

































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { SkyHubUser } from '../../models/SkyHubUser';
import { UserRole } from '@/models/UserRoles';

@Component<UserProfile>({})
export default class UserProfile extends Vue {
  @Prop({ default: {} })
  private currentUser!: SkyHubUser;

  private stringSeparator = 2;
  private roles = Object.values(UserRole);
  //this gets the labels of the roles
  private roleNames = this.roles.slice(
    0,
    this.roles.length / this.stringSeparator
  );

  get userViewModel() {
    return { ...this.currentUser };
  }

  private async updateUser(): Promise<void> {
    const user = { ...this.currentUser, ...this.userViewModel };
    await this.$store.dispatch('userModule/updateSkyHubUser', user);
  }
}
