var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "w-full max-w-md mx-auto mt-8" }, [
      _c(
        "form",
        {
          staticClass:
            "flex flex-col items-center px-8 pt-6 pb-8 mb-4 bg-white rounded shadow-md"
        },
        [
          _c(
            "div",
            {
              staticClass:
                "flex items-center justify-center w-24 h-24 mx-3 overflow-hidden text-3xl border border-gray-200 rounded-full shadow-lg"
            },
            [
              _vm.currentUser.photoUrl &&
              _vm.currentUser.photoUrl.startsWith("http")
                ? _c("img", {
                    attrs: {
                      src: _vm.currentUser.photoUrl,
                      alt: "Profile image"
                    }
                  })
                : _c("font-awesome-icon", {
                    staticClass: "fa-2x",
                    attrs: { icon: ["fas", "user"] }
                  })
            ],
            1
          ),
          _c(
            "h3",
            {
              staticClass:
                "mt-2 text-xl font-semibold leading-normal text-gray-800"
            },
            [_vm._v(" " + _vm._s(_vm.currentUser.email) + " ")]
          ),
          _c("div", { staticClass: "w-full my-4" }, [
            _c(
              "label",
              { staticClass: "block mb-2 text-sm font-bold text-gray-700" },
              [_vm._v(" Display Name ")]
            ),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.currentUser.displayName,
                  expression: "currentUser.displayName"
                }
              ],
              staticClass:
                "w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline",
              attrs: { type: "text" },
              domProps: { value: _vm.currentUser.displayName },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.currentUser, "displayName", $event.target.value)
                }
              }
            })
          ]),
          _c("div", { staticClass: "w-full mb-6" }, [
            _c(
              "label",
              { staticClass: "block mb-2 text-sm font-bold text-gray-700" },
              [_vm._v(" Role ")]
            ),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.roleNames[_vm.currentUser.role],
                  expression: "roleNames[currentUser.role]"
                }
              ],
              staticClass:
                "w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline",
              attrs: { placeholder: "", type: "text", disabled: "" },
              domProps: { value: _vm.roleNames[_vm.currentUser.role] },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.roleNames,
                    _vm.currentUser.role,
                    $event.target.value
                  )
                }
              }
            })
          ]),
          _c("div", { staticClass: "w-full mb-6" }, [
            _c(
              "label",
              { staticClass: "block mb-2 text-sm font-bold text-gray-700" },
              [_vm._v(" Photo Url ")]
            ),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.currentUser.photoUrl,
                  expression: "currentUser.photoUrl"
                }
              ],
              staticClass:
                "w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline",
              attrs: { type: "text" },
              domProps: { value: _vm.currentUser.photoUrl },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.currentUser, "photoUrl", $event.target.value)
                }
              }
            })
          ]),
          _c(
            "button",
            {
              staticClass: "skyhub-button",
              attrs: { type: "button" },
              on: { click: _vm.updateUser }
            },
            [_vm._v(" Update ")]
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }